
import PromiseBunny from "../images/bunnypromise.png"
import Mission from "../images/mission.png"
function LandingPage() {
  
    return (
      <div class="w-full bg-black text-white">
          <div class="w-full h-8"></div>
        <div class="w-full text-xl flex gap-3 justify-center flex-row">
            <div class="twenty_title text-red-500 text-2xl">20</div>
            <div class="frames_title text-green-500 text-3xl">Frames</div>
        </div>
        <div class="w-full text-center p-4 text-xl text-white">WOKE ENTREPRENEUR</div>
        <div class="w-full h-2  ro"></div>
        <div class="w-full text-center px-4 text-pink-300 text-3xl font-bold mt-6">only 40$</div>
        <div class="w-full text-center px-4 text-pink-300 text-xs font-bold mb-6">DM on <a class="underline" target="_blank" href="https://www.twitter.com/eliusthebot">twitter</a> to get a 30$ link</div>
        <div class="w-full  text-base font-light mb-4"><div class="w-11/12 flex justify-center mx-auto"><img src={Mission}/></div></div>
        <div class="w-full  text-base font-light"><div class="w-7/12 text-center mx-auto">Go from</div></div>
        <div class="w-full text-4xl font-bold"><div class="w-7/12 text-center mx-auto">unconscious</div></div>
        <div class="w-full text-base font-light"><div class="w-7/12 text-center mx-auto">to</div></div>
        <div class="w-full  text-4xl font-bold"><div class="w-7/12 text-center mx-auto">conscious</div></div>
        <div class="w-full p-4"><img class="px-16 mx-auto" alt="bunny promise" src={PromiseBunny}></img></div>
        <div class="w-full my-4">
            <div class="mx-auto border-2 font-bold rounded-lg text-lg border-red-500 bg-gray-100 w-10/12 p-6">
                <p class="text-black">Signup to Dashboard</p>
                <p class="text-green-600">(it's free)</p>
                <p class="text-red-500">BELOW IS THE CONTENT YOU GET</p>
            </div>
        </div>
        <div class="w-full text-center">
            LIST OF FRAMEWORKS
        </div>
        <div class="w-full text-center">
            Organisation
        </div>
        <div className="h-4"></div>
        <div className="h-4"></div>
        <div className="w-full text-center"> &#169; 123 VENTURES</div>

        <div className="h-4"></div>
        <div className="h-4"></div>
      </div>
    );
  }
  
  export default LandingPage;
  