import logo from './logo.svg';
import './App.css';
import HomePage from './screens/homepage';
import LandingPage from './screens/landingPage'
import { useEffect,useState } from 'react';


function getWindowDimensions() { 

  if (typeof window !== "undefined") {
    const { innerWidth, innerHeight } = window;

  return {
    innerWidth,
    innerHeight
  };
  }

  return {innerWidth:0,innerHeight:0}
  
}

function App() {


  const [l,setL] = useState(()=>getWindowDimensions())

  useEffect(()=>{
    setL(getWindowDimensions());
  })

  return (
    <div>
    {
    <div class="bg-black">
      <LandingPage/>
    </div>}
    </div>
  );
}

export default App;
