import Header from "../components/mobile/header";
import Thoughts from "../components/mobile/thoughts"
import ThreeDButton from "../components/mobile/3dbutton";
import {useState} from "react";

function HomePage() {
  
    return (
      <div class="h-full w-full">
        <Header/>
        <Thoughts/>
        <div class="h-8 w-full"></div>
        <ThreeDButton text="SUBMIT THOUGHT"/>

      </div>
    );
  }
  
  export default HomePage;
  